<template>
  <div v-if="desktop" class="manager-employee-container">
    <div class="header">
      <div class="search">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="search" type="text" placeholder="חיפוש" />
        </span>
      </div>
      <div class="date-from">
        <input type="date" v-model="fromDate" />
      </div>
      <div class="date-to">
        <input type="date" v-model="toDate" />
      </div>
      <div class="clear-dates">
        <Button
          label="איפוס תאריכים"
          class="p-button-secondary"
          @click="handleClearDates"
        />
      </div>
      <div class="export-to-excel">
        <Button
          icon="pi pi-file-excel"
          class="p-button-rounded p-button-success"
          @click="handleExportToCsv"
        />
      </div>
    </div>
    <div class="content">
      <table>
        <tr>
          <th @click="handleSortTable('תאריך הקליטה', !selectedSorted.order)">
            תאריך הקליטה
          </th>
          <th @click="handleSortTable('שם הסניף', !selectedSorted.order)">
            שם הסניף
          </th>
          <th
            @click="handleSortTable('תאריך תחילת עבודה', !selectedSorted.order)"
          >
            תאריך תחילת עבודה
          </th>
          <th @click="handleSortTable('שם פרטי', !selectedSorted.order)">
            שם פרטי
          </th>
          <th @click="handleSortTable('שם משפחה', !selectedSorted.order)">
            שם משפחה
          </th>
          <th @click="handleSortTable('תז', !selectedSorted.order)">ת.ז</th>
          <th @click="handleSortTable('תפקיד', !selectedSorted.order)">
            תפקיד
          </th>
          <th @click="handleSortTable('טלפון', !selectedSorted.order)">
            טלפון נייד
          </th>
          <th>סוג האזרחות</th>
          <th>תוקף אישור עבודה</th>
          <th @click="handleSortTable('hr', !selectedSorted.order)">
            דרך מי הגיע המועמד
          </th>
          <th>מדיה</th>
          <th>מסמך</th>
        </tr>
        <template v-for="detail in sortedRequests" :key="detail.id">
          <tr>
            <td>
              {{ detail.dateInterview.toDate().toLocaleDateString("he") }}
            </td>
            <td>{{ detail.branche }}</td>
            <td>
              {{ new Date(detail.startWorkingDate).toLocaleDateString("he") }}
            </td>
            <td>{{ detail.privateName }}</td>
            <td>{{ detail.lastName }}</td>
            <td>{{ detail.idNumber }}</td>
            <td>{{ detail.role }}</td>
            <td>{{ detail.phoneNumber }}</td>
            <td>{{ detail.citizenType }}</td>
            <td v-if="detail.validityPermission">
              {{ new Date(detail.validityPermission).toLocaleDateString("he") }}
            </td>
            <td v-else>-----</td>
            <td>{{ detail.hr }}</td>
            <td style="text-align:center;">
              <i
                id="media"
                class="pi pi-images"
                @click="handleShowMedia(detail)"
              ></i>
            </td>
            <td style="text-align:center;">
              <i
                id="pdf"
                class="pi pi-file-pdf"
                @click="handleSummaryPDF(detail.summaryPDF)"
              ></i>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>

  <Dialog header="תמונות וחתימות" v-model:visible="showMedia">
    <div class="media-container">
      <div class="media-box-1">
        <div class="images">
          <div class="image">
            <a :href="currentDetail.idFrontPic.url" target="_blank"
              ><img :src="currentDetail.idFrontPic.url" alt=""
            /></a>
          </div>
          <div class="image">
            <a :href="currentDetail.idRarePic.url" target="_blank"
              ><img :src="currentDetail.idRarePic.url" alt=""
            /></a>
          </div>
          <div class="image">
            <a :href="currentDetail.workerPic.url" target="_blank"
              ><img :src="currentDetail.workerPic.url" alt=""
            /></a>
          </div>
          <div class="image">
            <a :href="currentDetail.bankAccountPic.url" target="_blank"
              ><img :src="currentDetail.bankAccountPic.url" alt=""
            /></a>
          </div>
          <div v-if="currentDetail.haelthPic.url.length > 0" class="image">
            <a :href="currentDetail.haelthPic.url" target="_blank"
              ><img :src="currentDetail.haelthPic.url" alt="אני מסמך לחץ עליי"
            /></a>
          </div>
        </div>
      </div>
      <div class="media-box-2">
        <h3 style="text-align:center;">חתימות</h3>
        <div class="images">
          <div class="image">
            <h4>חתימת העובד בדבר פרטים אישיים</h4>
            <a :href="currentDetail.signature1.url" target="_blank"
              ><img :src="currentDetail.signature1.url" alt=""
            /></a>
          </div>
          <div class="image">
            <h4>חתימת העובד על נהלי המשמעת</h4>
            <a :href="currentDetail.signature2.url" target="_blank"
              ><img :src="currentDetail.signature2.url" alt=""
            /></a>
          </div>
          <div class="image">
            <h4>חתימת העובד על נהלי הבטיחות</h4>
            <a :href="currentDetail.signature3.url" target="_blank"
              ><img :src="currentDetail.signature3.url" alt=""
            /></a>
          </div>
          <div class="image">
            <h4>חתימת נאמן הבטיחות בדבר ביצוע ההדרכה לעובד</h4>
            <a :href="currentDetail.signature4.url" target="_blank"
              ><img :src="currentDetail.signature4.url"
            /></a>
          </div>
          <div class="image">
            <h4>חתימת העובד על קבלת נוסח תנאי העסקה</h4>
            <a :href="currentDetail.signature5.url" target="_blank"
              ><img :src="currentDetail.signature5.url"
            /></a>
          </div>
          <div class="image">
            <h4>חתימת מנהל המקבל את העובד</h4>
            <a :href="currentDetail.signature6.url" target="_blank"
              ><img :src="currentDetail.signature6.url"
            /></a>
          </div>
          <div class="image">
            <h4>חתימת העובד על מסירת הפרטים בתחקיר</h4>
            <a :href="currentDetail.signature7.url" target="_blank"
              ><img :src="currentDetail.signature7.url"
            /></a>
          </div>
          <div
            v-if="currentDetail.signaturePensia.url.lenght > 0"
            class="image"
          >
            <h4>חתימת העובד על קרן פנסיה פעילה</h4>
            <a :href="currentDetail.signaturePensia.url" target="_blank"
              ><img :src="currentDetail.signaturePensia.url"
            /></a>
          </div>
          <div v-if="currentDetail.signature8" class="image">
            <h4>חתימת הקב"ט</h4>
            <a :href="currentDetail.signature8.url" target="_blank"
              ><img :src="currentDetail.signature8.url"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </Dialog>

  <OnlyForDesktop v-if="!desktop" />
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import {projectFirestore} from '../../../../firebase/config'
import OnlyForDesktop from '../../../Not_responsible/OnlyForDesktop.vue'
import XLSX from "xlsx";
import { computed, onMounted, onUnmounted, ref } from "vue";
export default {
  components: { InputText, Dropdown, Dialog,OnlyForDesktop },
  setup() {
    const desktop = ref(true)
    const showMedia = ref(false)
    const fromDate=ref('')
    const toDate=ref('')
    const search = ref("");
    const details = ref([])
    const currentDetail = ref({})
    const selectedSorted = ref({
      name: "",
      order: false
    });
    const handleSortTable = (name, order) => {
      selectedSorted.value.name = name;
      selectedSorted.value.order = order;
    };
    const sortedRequests =ref(computed(()=>{
         if (selectedSorted.value.name == "") return filterRequestsBySearch.value;
          if (selectedSorted.value.name == "תאריך הקליטה") {
          return filterRequestsBySearch.value.sort((a, b) => {
            if (selectedSorted.value.order) {
              return  a.dateInterview.toDate() > b.dateInterview.toDate()
                ? 1
                : b.dateInterview.toDate() > a.dateInterview.toDate()
                ? -1
                : 0;
            }
            return a.dateInterview.toDate() >  b.dateInterview.toDate()
              ? -1
              : a.dateInterview.toDate() > b.dateInterview.toDate()
              ? 1
              : 0;
          });
          }
          if (selectedSorted.value.name == "שם הסניף") {
          return filterRequestsBySearch.value.sort((a, b) => {
            if (selectedSorted.value.order) {
              return  a.branche > b.branche
                ? 1
                : b.branche > a.branche
                ? -1
                : 0;
            }
            return a.branche >  b.branche
              ? -1
              : a.branche > b.branche
              ? 1
              : 0;
          });
          }
          if (selectedSorted.value.name == "תאריך תחילת עבודה") {
          return filterRequestsBySearch.value.sort((a, b) => {
            if (selectedSorted.value.order) {
              return  new Date(a.startWorkingDate) > new Date(b.startWorkingDate)
                ? 1
                : new Date(b.startWorkingDate) > new Date(a.startWorkingDate)
                ? -1
                : 0;
            }
            return new Date(a.startWorkingDate) > new Date(b.startWorkingDate)
              ? -1
              : new Date(a.startWorkingDate) > new Date(b.startWorkingDate)
              ? 1
              : 0;
          });
          }
          if (selectedSorted.value.name == "שם פרטי") {
          return filterRequestsBySearch.value.sort((a, b) => {
            if (selectedSorted.value.order) {
              return  a.privateName > b.privateName
                ? 1
                : b.privateName > a.privateName
                ? -1
                : 0;
            }
            return a.privateName >  b.privateName
              ? -1
              : a.privateName > b.privateName
              ? 1
              : 0;
          });
          }
          if (selectedSorted.value.name == "שם משפחה") {
          return filterRequestsBySearch.value.sort((a, b) => {
            if (selectedSorted.value.order) {
              return  a.lastName > b.lastName
                ? 1
                : b.lastName > a.lastName
                ? -1
                : 0;
            }
            return a.lastName >  b.lastName
              ? -1
              : a.lastName > b.lastName
              ? 1
              : 0;
          });
          }
          if (selectedSorted.value.name == "תז") {
          return filterRequestsBySearch.value.sort((a, b) => {
            if (selectedSorted.value.order) {
              return  a.idNumber > b.idNumber
                ? 1
                : b.idNumber > a.idNumber
                ? -1
                : 0;
            }
            return a.idNumber >  b.idNumber
              ? -1
              : a.idNumber > b.idNumber
              ? 1
              : 0;
          });
          }
          if (selectedSorted.value.name == "תפקיד") {
          return filterRequestsBySearch.value.sort((a, b) => {
            if (selectedSorted.value.order) {
              return  a.role > b.role
                ? 1
                : b.role > a.role
                ? -1
                : 0;
            }
            return a.role >  b.role
              ? -1
              : a.role > b.role
              ? 1
              : 0;
          });
          }
          if (selectedSorted.value.name == "טלפון") {
          return filterRequestsBySearch.value.sort((a, b) => {
            if (selectedSorted.value.order) {
              return  a.phoneNumber > b.phoneNumber
                ? 1
                : b.phoneNumber > a.phoneNumber
                ? -1
                : 0;
            }
            return a.phoneNumber >  b.phoneNumber
              ? -1
              : a.phoneNumber > b.phoneNumber
              ? 1
              : 0;
          });
          }
          if (selectedSorted.value.name == "hr") {
          return filterRequestsBySearch.value.sort((a, b) => {
            if (selectedSorted.value.order) {
              return  a.hr > b.hr
                ? 1
                : b.hr > a.hr
                ? -1
                : 0;
            }
            return a.hr >  b.hr
              ? -1
              : a.hr > b.hr
              ? 1
              : 0;
          });
          }

    }))
    const filterRequestsBySearch=ref(computed(()=>{
        if(search.value == ""){
            if(fromDate.value && toDate.value){
                return details.value.filter(detail=>{
                    if(stringTodate(fromDate.value)<=timestampToDate(detail.dateInterview) &&
                    stringTodate(toDate.value)>=timestampToDate(detail.dateInterview) ){
                        return detail
                    }
                })
          }
            return details.value
        }else{
            return details.value.filter(detail=>{
                if(detail.dateInterview.toDate().toLocaleDateString('he').includes(search.value)) return detail
                if(detail.branche.includes(search.value)) return detail
                if(new Date(detail.startWorkingDate).toLocaleDateString('he').includes(search.value)) return detail
                if(detail.privateName.includes(search.value)) return detail
                if(detail.lastName.includes(search.value)) return detail
                if(detail.idNumber.includes(search.value)) return detail
                if(detail.role.includes(search.value)) return detail
                if(detail.phoneNumber.includes(search.value)) return detail
                if(detail.hr.includes(search.value)) return detail
            })
        }
    }))
   
    const handleShowMedia = (detail)=>{
        showMedia.value = !showMedia.value
        currentDetail.value = detail
    }
    const handleClearDates = ()=>{
      fromDate.value=''
      toDate.value=''
    }
    const handleSummaryPDF = (pdf)=>{
      window.open(pdf.url)
    }
    const stringTodate=(dateString)=>{
      const d=new Date(dateString)
      d.setHours(0,0,0,0)
      return d
    }
    const timestampToDate=(date)=>{
        const d = new Date(date.seconds * 1000)
        d.setHours(0,0,0,0)
        return d
    }

    const handleExportToCsv=()=>{
      let rows = JSON.parse(JSON.stringify(sortedRequests.value)) 
      rows.forEach(row=>{
        row['תאריך הקליטה']=new Date(row.dateInterview.seconds * 1000).toLocaleDateString('he')
        row['שם הסניף']=row.branche
        row['תאריך תחילת עבודה']=new Date(row.startWorkingDate).toLocaleDateString('he')
        row['שם פרטי']=row.privateName
        row['שם משפחה']=row.lastName
        row['ת.ז']=row.idNumber
        row['סוג אזרחות']=row.citizenType
        row['תוקף אישור עבודה']=row.validityPermission
        row['תפקיד']=row.role
        row['טלפון נייד']=row.phoneNumber
        row['דרך מי הגיע המועמד']=row.hr
        delete row.dateInterview
        delete row.branche
        delete row.startWorkingDate
        delete row.privateName
        delete row.lastName
        delete row.ownerName
        delete row.idNumber
        delete row.role
        delete row.phoneNumber
        delete row.hr
        delete row.drugs
        delete row.workerPic
        delete row.city
        delete row.alcohol
        delete row.kupatHolim
        delete row.charge
        delete row.charge
        delete row.haelthPic
        delete row.haelthPic
        delete row.motherName
        delete row.motherName
        delete row.email
        delete row.bankAccountPic
        delete row.signaturePensia
        delete row.signature1
        delete row.execution
        delete row.kabatRecomendation
        delete row.signature6
        delete row.signature5
        delete row.signature4
        delete row.signature3
        delete row.signature2
        delete row.birthDate
        delete row.startSalary
        delete row.dateSecurityInterview
        delete row.religion
        delete row.mikud
        delete row.aliyaDate
        delete row.citizenType
        delete row.fatherName
        delete row.isChildren
        delete row.health
        delete row.childrens
        delete row.managerName
        delete row.incomingFromOtherPlace
        delete row.idFrontPic
        delete row.jobsPlacesBeforeRosman
        delete row.pensia
        delete row.numberBankBrnache
        delete row.bankAccount
        delete row.apartmentNumber
        delete row.houseNumber
        delete row.incomingPlace
        delete row.id
        delete row.workedBeforeInRosman
        delete row.criminalOffenses
        delete row.idRarePic
        delete row.tadDoar
        delete row.birthCountry
        delete row.familyStatus
        delete row.signature7
        delete row.street
        delete row.workedBeforeDetails
        delete row.validityPermission
        delete row.workedBeforeInRosmanDetails
        delete row.bankName
        delete row.summaryPDF
        delete row.pdfSummary
        delete row.kabatName
        delete row.kabatComments
        delete row.signature8
      })

        let workSheet = XLSX.utils.json_to_sheet(rows);
        let workbook = XLSX.utils.book_new();
        workbook.Workbook = {};
        workbook.Workbook.Views = [];
        workbook.Workbook.Views[0] = {};
        workbook.Workbook.Views[0].RTL = true;

        XLSX.utils.book_append_sheet(workbook, workSheet, "קליטת עובדים");
        XLSX.writeFile(workbook, "klitat_ovdim.xlsx");
    }
     window.addEventListener('resize', ()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
     });
    onMounted(async () => {
        if(window.innerWidth<600){
          desktop.value = false
        }else{
          desktop.value = true
        }

       getRequestsFromDB();
    });
   
   let unsub
   const getRequestsFromDB = () => {
    unsub = projectFirestore.collection("Applications")
    .doc("7KmZyi2hObdZuRczUNbr")
    .collection("Klitat_oved")
    .onSnapshot(snapshot => {
        snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    details.value.push(change.doc.data())
                    details.value = details.value.sort((a,b)=>b.dateInterview.toDate() - a.dateInterview.toDate())
                }
                if (change.type === "modified") {
                    const index = details.value.findIndex(detail=>detail.id == change.doc.data().id)
                    details.value[index] = change.doc.data()
                    details.value = details.value.sort((a,b)=>b.dateInterview.toDate() - a.dateInterview.toDate())
                }
                if (change.type === "removed") {
                    const index = details.value.findIndex(detail=>detail.id == change.doc.data().id)
                    details.value.splice(index, 1);
                    details.value = details.value.sort((a,b)=>b.dateInterview.toDate() - a.dateInterview.toDate())
                }
            })
    })};

    onUnmounted(()=>{
        window.removeEventListener('resize', ()=>{
          if(window.innerWidth<600){
              desktop.value = false
          }else{
              desktop.value = true
          }
        });
        if(unsub){
            unsub()
        }
    })

    
    return {
      handleSortTable,
      handleClearDates,
      handleExportToCsv,
      handleShowMedia,
      handleSummaryPDF,
      search,
      details,
      filterRequestsBySearch,
      selectedSorted,
      sortedRequests,
      fromDate,
      toDate,
      showMedia,
      currentDetail,
      desktop
    };
  }
};
</script>

<style scoped>
.manager-employee-container {
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  overflow: hidden;
  overflow-y: scroll;
}

.header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.header .search {
  margin-left: 5px;
}
.content {
  margin-top: 10px;
  width: 100%;
  height: calc(100% - 60px);
  overflow: hidden;
  overflow-y: auto;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: right;
  padding: 8px;
}
th {
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

tr:hover {
  background: lightskyblue;
}

i {
  cursor: pointer;
}

#media:hover {
  color: #f7b00c;
}

.mashov-container {
  position: relative;
  width: 50vw;
  height: 60vh;
}

input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}

.mashov-container .explaination h4 {
  margin-bottom: 5px;
}

.mashov-container .fileds {
  width: 100%;
  height: 20vh;
  margin-top: 15px;
}
.mashov-container .field {
  width: 100%;
  margin-bottom: 10px;
}
.mashov-container .field p {
  font-weight: bold;
}

.delete-btn {
  position: absolute;
  width: 20%;
  bottom: 0;
  left: 0;
}

.media-container{
    width: 70vw;
    height: 60vh;
    display: flex;
}
.media-box-1{
    width: 50%;
    height: 100%;

}
.media-box-2{
    width: 50%;
    height: 100%;
}
.images{
    width: 100%;
    height: calc(100% - 25px);
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.images .image{
    width: 100%;
}
.images .image img{
    width: 100%;
}

#pdf:hover{
  color: red;
}

@media only screen and (max-width: 600px){
    .mashov-container {
        position: relative;
        width: 70vw;
        height: 60vh;
    }
    .delete-btn {
        position: absolute;
        width: 40%;
        bottom: 0;
        left:0;
       
    }
}
</style>
