<template>
  <div :id="sketchName">
    <canvas
      class="paint"
      :id="idName"
      @mousemove="handleMouseMove($event)"
      @mousedown="handleMouseDown($event)"
      @mouseup="handleMouseUp($event)"
    ></canvas>
    <canvas id="blank" style="display:none"></canvas>
  </div>
</template>

<script>
/* eslint-disable */
import { onMounted } from "vue";
export default {
  props:['idName','sketchName'],
  setup(props) {
    let canvas = null;
    let ctx = null;
    let sketch = null;
    let sketch_style = null;

    let mouse = { x: 0, y: 0 };

    const handleMouseMove = e => {
      let rect = canvas.getBoundingClientRect();
      mouse.x = e.clientX - rect.left;
      mouse.y = e.clientY - rect.top;
    };

    const handleMouseDown = () => {
      ctx.beginPath();
      ctx.moveTo(mouse.x, mouse.y);
      canvas.addEventListener("mousemove", onPaint, false);
    };

    const handleMouseUp = () => {
      canvas.removeEventListener("mousemove", onPaint, false);
    };
    const onPaint = function() {
      ctx.lineTo(mouse.x, mouse.y);
      ctx.stroke();
    };

    onMounted(() => {
      canvas = document.getElementById(props.idName);
      ctx = canvas.getContext("2d");
      sketch = document.getElementById(props.sketchName);
      sketch_style = getComputedStyle(sketch);
      canvas.width = 500;
      canvas.height = 100;
      /* Drawing on Paint App */
      ctx.lineJoin = "round";
      ctx.lineCap = "round";
      ctx.strokeStyle = "black";
    });
    return { handleMouseMove, handleMouseDown, handleMouseUp };
  }
};
</script>

<style scoped>
* {
  font-family: sans-serif;
}
#settings {
  display: block;
  text-align: center;
}
.paint {
  border: 1px solid black;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  cursor: crosshair;
}
</style>
