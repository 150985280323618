<template>
  <div class="klitat-oved-main">
    <!-- <Button @click="state='branche'" v-if="!state"  style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="טופס קליטת עובד" class="p-button-success" /> -->
    <Button  @click="state='manage'" v-if="!state && user_role>=99"  style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="ניהול טפסים" class="p-button-success" />
      <Button v-if="!state" @click="push_to_tofes_online('101')" style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="טופס 101 רוסמן מרקט" class="p-button-warning" />
      <Button v-if="!state" @click="push_to_tofes_online('123')" style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="טופס קליטת עובד אונליין" class="p-button-success" />
      <Button v-if="!state" @click="push_to_tofes_online('ביוטי פארם')" style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="טופס קליטת עובד ביוטי פארם" class="p-button-warning" />
      <Button v-if="!state" @click="push_to_tofes_online('ביוטי101')" style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="טופס 101 ביוטי פארם" class="p-button-success" />

    <ChooseBranche v-if="state=='branche'" @selectedBranch='handle_selected_branche($event)' />
    <KlitatOvedForm v-if="state=='form'" :branche="branche" @finish="state=null" />
    <ManagerKlitatOved v-if="state=='manage'" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import ChooseBranche from '../klitat_oved/components/ChooseBranche.vue'
import KlitatOvedForm from '../klitat_oved/components/KlitatOvedForm.vue'
import ManagerKlitatOved from '../klitat_oved/components/ManagerKlitatOved.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '../../../store'
import { computed } from '@vue/runtime-core'


export default {
  components:{ChooseBranche,KlitatOvedForm,ManagerKlitatOved},
  setup(){
    const state = ref(false)
    const branche  = ref('')
    const handle_selected_branche=(event)=>{
      branche.value = event
      state.value = 'form'
    }

    const push_to_tofes_online = (tofes) => {
      if(tofes=='123'){
        window.open('https://form.123formbuilder.com/6518278/shivok-rosman-employment-form', '_blank');
      }
      else if(tofes=='101'){
        window.open('https://tpz.link/zcqwr', '_blank');
      }
      else if(tofes=='ביוטי פארם'){
        window.open('https://form.123formbuilder.com/6547491/form', '_blank');
      }
      else if(tofes=='ביוטי101'){
        window.open('https://tpz.link/zq8bj', '_blank');
      }
    }
    const user_role = ref(computed(()=>{
            return store.getters.role
    }))
    onBeforeRouteLeave((to,from,next)=>{
        if(state.value == 'manage' || state.value == 'branche' || state.value=='form'){
          next(false)
          state.value = null
        }else{
          next()
        }
    })
    return{state,handle_selected_branche,branche,user_role,push_to_tofes_online}
  }
}
</script>

<style scoped>
    .klitat-oved-main{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>